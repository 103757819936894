<script>
import TravelService from "@/services/TravelService";
import dayjs from "dayjs";

export default {
    props: {
        exportedDate: {
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            travels: [],
        };
    },
    computed: {
        exportDateUrl() {
            const dt = dayjs(this.exportedDate);
            return `/v1/travels/salary-report/${encodeURIComponent(
                dt.format("YYYY-MM-DD HH:mm:ss")
            )}/csv`;
        },
    },
    async created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const dt = dayjs(this.exportedDate);
                const { data } = await TravelService.GET_salaryReport(
                    dt.format("YYYY-MM-DD HH:mm:ss")
                );
                this.travels = data.data;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
    },
};
</script>

<template>
    <modal
        size="xxl"
        :show="true"
        :loading="loading"
        @close="$emit('close')"
        class="asset-modal"
    >
        <template slot="header">
            {{ $t("salary.tp.header_salary_report") }} -
            {{ $d(new Date(exportedDate), "longFull") }}

            <a class="btn btn-primary btn-sm ml-3" :href="exportDateUrl">{{
                $t("salary.btn_export_to_CSV")
            }}</a>
        </template>

        <template slot="default">
            <div class="" v-for="(report, index) in travels" :key="index">
                <h4>{{ report.name }}: {{ report.kms }} km</h4>
                <v-table class="table-mg table table-sm">
                    <v-thead>
                        <v-tr>
                            <v-th class="w-15">{{
                                $t("travels.index.tbl_header_date")
                            }}</v-th>
                            <v-th class="w-40">{{
                                $t("travels.index.tbl_header_case")
                            }}</v-th>
                            <v-th class="w-25">{{
                                $t("travels.index.tbl_header_text")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_rate")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_kms")
                            }}</v-th>
                            <v-th class="text-right">{{
                                $t("travels.index.tbl_header_km_money")
                            }}</v-th>
                        </v-tr>
                    </v-thead>
                    <v-tbody>
                        <v-tr v-for="t in report.data" :key="t.id">
                            <v-td>{{
                                $d(new Date(t.travel_date), "short")
                            }}</v-td>
                            <v-td>
                                <template v-if="t.project_id">
                                    {{ t.project_title }}
                                    <div class="small text-muted">
                                        #{{ t.project_id }}
                                    </div>
                                </template>
                                <template v-else>---</template>
                            </v-td>
                            <v-td
                                >{{ t.invoice_text }} /
                                {{ t.notes }}
                            </v-td>
                            <v-td class="text-right">{{
                                $n(t.tax_rate_per_km, {
                                    style: "currency",
                                    currency: t.tax_rate_currency,
                                    currencyDisplay: "code",
                                })
                            }}</v-td>
                            <v-td class="text-right">{{ t.kms }} km</v-td>
                            <v-td class="text-right">{{
                                $n(t.tax_rate_per_km * t.kms, {
                                    style: "currency",
                                    currency: t.tax_rate_currency,
                                    currencyDisplay: "code",
                                })
                            }}</v-td>
                        </v-tr>
                    </v-tbody>
                </v-table>
            </div>
        </template>
    </modal>
</template>


<style lang="scss">
.w-15 {
    width: 15% !important;
}
</style>
