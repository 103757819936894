<script>
import TravelReportModal from "@/views/Travels/TravelReportModal";
import { mapGetters } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import VFilters from "@/components/Search/VFilters";
import TravelType from "@/components/Travel/TravelType";
import VTravelForm from "@/components/Forms/VTravelForm";
import TravelService from "@/services/TravelService";
import ProjectService from "@/services/ProjectService";
import UserService from "@/services/UserService";
import MessagesMixin from "@/mixins/Messages";

export default {
    name: "VSalary",
    mixins: [DateRangeMixin, MessagesMixin],
    components: {
        VFilters,
        DateRangePicker,
        TravelReportModal,
    },
    data() {
        return {
            loading: true,
            reports: [],
            meta: {},
            showModal: false,
            modalContext: "",
        };
    },
    created() {
        this.resetSearch();
        this.fetchData();
        this.$apiEnableWatcher();
    },

    methods: {
        showReportModal(dt) {
            this.modalContext = dt;
            this.showModal = true;
        },
        closeReportModal() {
            this.modalContext = "";
            this.showModal = false;
        },
        async fetchData() {
            try {
                this.loading = true;
                let params = this.$buildParams();
                const { data } = await TravelService.GET_salaryReports(params);
                this.reports = data.data;
                this.meta = data.meta;
            } catch (e) {
                console.log("f e", e);
            }

            this.loading = false;
        },
        async resetSearch() {
            this.apiSearchFilter = {
                dateRange: {
                    startDate: null,
                    endDate: null,
                },
            };
        },
    },
};
</script>
<template>
    <div class="v-travels-index">
        <base-header class="pb-1" type=""><bread-bag></bread-bag></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-12">
                    <card body-classes="px-0">
                        <!-- V-FILTERS -->
                        <div class="px-4 mb-4" v-if="true">
                            <v-filters
                                :search="false"
                                :start-open="true"
                                :enable-filters="true"
                                @reset-search="resetSearch"
                                @filter-view-change="$handleFilterView($event)"
                            >
                                <!-- Default slot starts here -> filters -->
                                <div class="col-12 col-md-4">
                                    <date-range-picker
                                        class="range-picker"
                                        ref="picker"
                                        opens="right"
                                        :timePicker="false"
                                        :showWeekNumbers="true"
                                        :autoApply="true"
                                        v-model="apiSearchFilter.dateRange"
                                        :linkedCalendars="true"
                                        :locale-data="
                                            getDateRangePickerLocale()
                                        "
                                        :ranges="getDateRangePickerRanges()"
                                    >
                                        <template #header>
                                            <manual-range
                                                :date-range-manual="
                                                    dateRangeManual
                                                "
                                                :date-range-error="
                                                    dateRangeError
                                                "
                                                @exec="
                                                    execManualRange(
                                                        'apiSearchFilter',
                                                        'dateRange'
                                                    )
                                                "
                                            />
                                        </template>
                                        <template
                                            v-slot:input="picker"
                                            style="min-width: 350px"
                                        >
                                            <template v-if="picker.startDate">
                                                {{
                                                    $d(
                                                        picker.startDate,
                                                        "short"
                                                    )
                                                }}
                                                -
                                                {{
                                                    $d(picker.endDate, "short")
                                                }}
                                            </template>
                                        </template>
                                    </date-range-picker>
                                </div>
                                <!-- daterange -->
                            </v-filters>
                        </div>

                        <v-loader :loading="loading" v-if="loading" />
                        <template v-else-if="reports.length > 0">
                            <div class="px-4 mb-4">
                                {{ $t("salary.salary_export_lead_in") }}
                            </div>
                            <v-table class="table-mg table table-sm">
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("salary.salary_report.tbl_count")
                                        }}</v-th>

                                        <v-th class="text-right">{{
                                            $t(
                                                "salary.salary_report.tbl_export_date"
                                            )
                                        }}</v-th>
                                        <v-th></v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr
                                        v-for="(t, index) in reports"
                                        :key="index"
                                    >
                                        <v-td>{{
                                            $d(
                                                new Date(t.exported_date),
                                                "longFull"
                                            )
                                        }}</v-td>
                                        <v-td class="text-right">{{
                                            t.count
                                        }}</v-td>
                                        <v-td class="text-center"
                                            ><i
                                                class="text-primary m-click fa fa-eye fa-lg"
                                                @click="
                                                    showReportModal(
                                                        t.exported_date
                                                    )
                                                "
                                            ></i
                                        ></v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                            <div class="paginator">
                                <base-pagination
                                    :page-count="meta.pagination.total_pages"
                                    :per-page="meta.pagination.per_page"
                                    :value="meta.pagination.current_page"
                                    @input="$handlePagination($event)"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="px-4">
                                <div class="alert alert-warning">
                                    {{ $t("travels.no_data_found") }}
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <portal to="modals">
            <travel-report-modal
                v-if="showModal"
                @close="closeReportModal"
                :exported-date="modalContext"
            />
        </portal>
    </div>
</template>

<style lang="scss">
</style>
